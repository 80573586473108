import React, { useCallback } from 'react';
import dynamic from 'next/dynamic';
import { useEditablePageData } from '../../providers/EditablePageProvider';
import styles from './ModuleWrapper.module.scss';
import clsx from 'clsx';

export enum PayloadContentType {
    Accordion = 'accordion',
    Contact = 'contact',
    ContactTeaser = 'contact-teaser',
    Downloads = 'downloads-block',
    EventTeaser = 'event-teaser',
    Events = 'events',
    Newsletter = 'newsletter',
    RichText = 'rich-text',
    Teaser = 'teaser',
    TeaserTwoCol = 'teaser-two-col',
    TextImage = 'text-image',
    Video = 'video',
}

export type FrontendPageContent = {
    headline: string;
    id: string;
    blockType: PayloadContentType;
};

const Contact = dynamic(() => import('../modules/Contact'));
const Events = dynamic(() => import('../modules/events'));

const ModuleHead = dynamic(() => import('./ModuleHead'));

const RichText = dynamic(() => import('../modules/RichText'));
const TextImage = dynamic(() => import('../modules/TextImage'));
const ContactTeaser = dynamic(() => import('../modules/ContactTeaser'));
const Teaser = dynamic(() => import('../modules/Teaser'));
const TeaserTwoCol = dynamic(() => import('../modules/TeaserTwoCol'));
const Video = dynamic(() => import('../modules/Video'));

const Accordion = dynamic(() => import('../modules/Accordion'));
const Downloads = dynamic(() => import('../modules/Downloads'));
const Newsletter = dynamic(() => import('../modules/Newsletter'));

type Props = {
    pageContent: FrontendPageContent;
};

const getInnerModule = (
    pageContent: FrontendPageContent,
    moduleHead: React.ReactElement,
) => {
    const sharedProps = {
        pageContent,
        moduleHead,
    };

    switch (pageContent.blockType) {
        case PayloadContentType.Accordion:
            return <Accordion {...sharedProps} />;
        case PayloadContentType.Contact:
            return <Contact {...sharedProps} />;
        case PayloadContentType.ContactTeaser:
            return <ContactTeaser {...sharedProps} />;
        case PayloadContentType.Downloads:
            return <Downloads {...sharedProps} />;
        case PayloadContentType.Events:
            return <Events {...sharedProps} />;
        case PayloadContentType.Newsletter:
            return <Newsletter {...sharedProps} />;
        case PayloadContentType.RichText:
            return <RichText {...sharedProps} />;
        case PayloadContentType.Teaser:
            return <Teaser {...sharedProps} />;
        case PayloadContentType.TeaserTwoCol:
            return <TeaserTwoCol {...sharedProps} />;
        case PayloadContentType.TextImage:
            return (
                <TextImage pageContent={pageContent} moduleHead={moduleHead} />
            );
        case PayloadContentType.Video:
            return <Video pageContent={pageContent} moduleHead={moduleHead} />;
        default:
            return <span>Implement {pageContent.contentType}</span>;
    }
};

const ModuleWrapper: React.FC<Props> = (props) => {
    const { isInEditMode, setFocussedComponent, focussedComponent } =
        useEditablePageData();
    const moduleHead = <ModuleHead content={props.pageContent} />;

    const handleClick = useCallback(() => {
        setFocussedComponent(props.pageContent.id);
    }, [props.pageContent.id]);

    const module = getInnerModule(props.pageContent, moduleHead);

    if (isInEditMode) {
        return (
            <div
                id={`content-${props.pageContent.id}`}
                className={isInEditMode ? styles.editing : undefined}
            >
                <div className={clsx(styles.button_container)}>
                    {focussedComponent === props.pageContent.id && (
                        <div
                            className="mo-edit-icon active"
                            onClick={handleClick}
                        >
                            0
                        </div>
                    )}
                    {focussedComponent !== props.pageContent.id && (
                        <div className="mo-edit-icon" onClick={handleClick}>
                            0
                        </div>
                    )}{' '}
                </div>

                {module}
            </div>
        );
    } else {
        return <div id={`content-${props.pageContent.id}`}>{module}</div>;
    }
};

export default ModuleWrapper;
